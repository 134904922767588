import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./componenets/Home";


function App() {
  return (

    <div className="App">


      <Router >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:Link" element={<Home />} />
        </Routes>
      </Router>


    </div >


  );
}

export default App;
