import React, { useRef, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import mapboxgl from 'mapbox-gl';
import emailjs from 'emailjs-com';
import axios from "axios";

//images
import hook from './images/hook.png'
import valid from './images/valid.png'
import speed from './images/speed.png'
import free from './images/free.png'
import vhu from './images/vhu.png'
import loading from './images/loading.gif'
import logo from './images/logo.png'


function Home() {

    // map and region functionality

    // data loading
    const [Loading, setLoading] = useState(true)
    //routing and town setup
    const { Link } = useParams()
    const [Town, setTown] = useState("TOUTE LA FRANCE")
    const [pref, setpref] = useState("")
    //map essensial variables
    const mapContainer = useRef(null);
    const map = useRef(null);
    //setting up town and map coordinate from the recieved data
    useEffect(() => {

        //setLoading(true)

        mapboxgl.accessToken = 'pk.eyJ1IjoiaHJpdHRhbmUiLCJhIjoiY2t3NjlyemtmMDZ2bDJxczJ0NWJwNnk5eCJ9.fFgclAE8ScfhoPatNLgDGg';

        if (map.current) return; // initialize map only once


        if (Link !== undefined) {

            axios.get(`https://CAR-CASSE.FRdb.herokuapp.com/departements?link=${Link}`).then(response => {

                const { data } = response

                if (data.length > 0) {

                    map.current = new mapboxgl.Map({
                        container: mapContainer.current,
                        style: 'mapbox://styles/hrittane/ckw6n0gk501pv15qn0xnpai11',
                        center: [data[0].coordinates.Longtitude, data[0].coordinates.Latitude],
                        zoom: data[0].coordinates.zoom,
                        scrollZoom: false

                    });

                    setTown(data[0].name)
                    setpref(data[0].Pref)
                    setLoading(false)

                } else {

                    map.current = new mapboxgl.Map({
                        container: mapContainer.current,
                        style: 'mapbox://styles/hrittane/ckw6n0gk501pv15qn0xnpai11',
                        center: [2.2137, 46.2276],
                        zoom: 5,
                        scrollZoom: false

                    });

                    setLoading(false)
                }

            })

        } else {

            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/hrittane/ckw6n0gk501pv15qn0xnpai11',
                center: [2.2137, 46.2276],
                zoom: 5,
                scrollZoom: false

            });

            setLoading(false)
        }


    }, [Link]);

    //email functionality

    /* forms visibility */
    const [FormVisibleOne, setFormVisibleOne] = useState(true)
    const [FormVisibleTwo, setFormVisibleTwo] = useState(true)
    /* first form */
    const form = useRef();
    const AskForInformations = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ryzq25i', 'template_18gdwbs', form.current, 'fV47WL8KqCJpUvu02')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()

        setFormVisibleOne(false)

    };
    /* second form */
    const fullform = useRef();
    const SendFullInformations = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_ryzq25i', 'template_oxscaem', fullform.current, 'fV47WL8KqCJpUvu02')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()

        setFormVisibleTwo(false)

    };


    return (

        <>
            {Loading &&

                <div class="Loading text-center" id="two-gears">
                    <div className="content" >
                        <img src={loading} class="img-fluid gears" alt="loading"></img>
                    </div>
                </div>
            }

            {/* navs and form part*/}
            <div className="hero">

                {/* first nav */}
                <header className="text-white firstNav">

                    <div className="container d-flex flex-wrap justify-content-between">

                        <ul class="nav justify-content-center">
                            <li><a href="/" class="text-light nav-link px-2 link-dark"> <i class="fab fa-facebook-square"></i> </a></li>
                            <li><a href="/" class="text-light nav-link px-2 link-dark"> <i class="fab fa-instagram"></i> </a></li>
                            <li><a href="/" class="text-light nav-link px-2 link-dark"> <i class="fab fa-twitter"></i> </a></li>
                            <li><a href="/" class="text-light nav-link px-2 link-dark"> <i class="fas fa-rss"></i> </a></li>
                        </ul>

                        <ul class="nav justify-content-center">
                            <li><a href="/" class="text-light nav-link px-2 link-dark"><i class="far fa-question-circle"></i> Faq</a></li>
                            <li><a href="#exampleModal" data-bs-toggle="modal" data-bs-target="#exampleModal" class="text-light nav-link px-2 link-dark"><i class="fas fa-exclamation-circle"></i> mentions légales</a></li>
                        </ul>

                    </div>

                </header>

                {/* modal */}
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">Mentions Légales CAR-CASSE.FR</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <p>
                                    CAR-CASSE.FR propose la mise en relation entre ses utilisateurs et des professionnels.
                                    <br></br>
                                    <br></br>
                                    Par utilisateurs, seront désignés tous les visiteurs du site www.CAR-CASSE.FR ayant fait une demande par formulaire de contact, appel téléphonique, email ou tout autres moyens de communication.Par professionnel, seront désignés les centre VHU et professionnels de l’automobile fournissant des services d’enlèvement de véhicule.
                                    <br></br>
                                    <br></br>
                                    Les informations fournies sur le site www.CAR-CASSE.FR sont fournies à titre indicatif, elles sont non contractuelles et susceptibles d’être modifiées à tout moment.CAR-CASSE s’efforce de tenir à jour et exactes les informations diffusées, sans pouvoir les garantir, ni en être tenu responsable.
                                    Nous vous invitons à nous signaler toute erreur ou omission via l’adresse mail suivante :contact@car-casse.fr .
                                    <br></br>
                                    <br></br>
                                    Si des utilisateurs en font une demande, CAR-CASSE.FR met en relation ses utilisateurs avec des professionnels.Nous pouvons être amenés à accompagner nos utilisateurs à rassembler les documents nécessaires à l’enlèvement de leur véhicule afin de faciliter le contact entre nos utilisateurs et les professionnels sans pour autant être considéré comme responsable des termes du contrat qui sera réalisé entre nos utilisateurs et les professionnels.
                                    <br></br>
                                    <br></br>
                                    Nous vérifions la qualité des professionnels que nous recommandons, néanmoins, nous ne saurions pas être responsable où garant des prestations effectuer par les professionnels avec lesquels nous vous aurons mis en relation.
                                    <br></br>
                                    En aucun cas, CAR-CASSE ne pourra être tenu responsable des termes des contrats qui auront été conclus entre les utilisateurs et les professionnels. Ainsi, la responsabilité d’CAR-CASSE ne saurait être engagée par tout incident entre ses utilisateurs et les professionnels, dont les professionnels pourraient être responsable.
                                    <br></br>
                                    Concernant les données transmises par les utilisateurs, elles seront enregistrées dans la base de données d’CAR-CASSE et seront destinées à l’usage interne d’CAR-CASSE et des professionnels avec qui les utilisateurs seront mis en relation.
                                    <br></br>
                                    Aussi n’autres informations peuvent être stocké lors de votre utilisation du site www.CAR-CASSE.FR tel que les ‘’cookies’’. Ces informations nous permettent de mieux cerner les attentes de nos utilisateurs afin d’améliorer leur expérience sur notre site.
                                    <br></br>
                                    <br></br>
                                    Pour toute demande concernant vos données personnelles, nous vous invitons à nous contacter via le courriel suivant :contact@car-casse.fr
                                    <br></br>
                                    <br></br>
                                    CAR-CASSE<br></br>
                                    5 Imp. des hirondelles 33700 <br></br>
                                    Mérignac, France<br></br>
                                    Email :contact@car-casse.fr<br></br>
                                    Hébergeur : NETLIFY<br></br>
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* second nav */}
                <div className="secoundNav">

                    <div className="container">

                        <div className="row">
                            <div className="col-lg-4 col-sm-12 text-left text-white navItem">
                                {/* <pre className="fw-bold pt-3 logo"><span >EPAV<i class="fas fa-cog orangeText"></i>R</span></pre> */}
                                <img src={logo} class="rounded logo float-left mt-3" alt="..." />

                            </div>
                            <div className="col-lg-4 col-sm-12 text-center text-white navItem">
                                <pre className="fw-bold pt-3 callToAction"><span className=" orangeText" ><i class="fas fa-phone orangeText"></i>  APPELEZ-NOUS :<br></br></span>   <a className="text-decoration-none text-light" href="tel:+33756908079">+33 7 56 90 80 79</a></pre>
                            </div>
                            <div className="col-lg-4 col-sm-12 text-end text-white navItem">
                                <pre className="fw-bold pt-3 callToAction "><span className="fw-bold orangeText" ><i class="fas fa-envelope-open-text orangeText"></i>  CONTACTEZ-NOUS :<br></br></span>  <a className="text-decoration-none text-light" href="mailto:contact@car-casse.fr" >contact@car-casse.fr</a></pre>
                            </div>
                        </div>

                    </div>

                </div>

                {/* first form */}
                <div className="container py-5">

                    <div className="row ">

                        <div className="row text-light mx-2 mb-3">

                            <h3 className="fw-bold animate__animated animate__fadeInUp"> <span className="orangeText" >ENLEVEMENT D´ÉPAVE</span>   <span className="orange"> GRATUIT </span> </h3>
                            <h1 class="fw-normal fw-bold animate__animated animate__fadeInUp"> {pref.toUpperCase()} DANS {Town.toUpperCase()}</h1>

                        </div>

                        <div className="col-lg-4">

                            <div className="p-lg-4 p-3 mb-4 bg-dark rounded-3 animate__animated animate__fadeInLeft h-100 " >

                                {FormVisibleOne &&
                                    <form ref={form} onSubmit={AskForInformations}>
                                        <h4 className="text-light fw-bold">DEMANDE D'INFORMATIONS</h4>
                                        <p className=" text-light ">
                                            Pour toute demande concernant un enlèvement de véhicule,
                                            nous vous invitons à nous laisser vos coordonnées afin que l'un de nos agents puisse vous appeler et de vous renseigner.
                                        </p>
                                        <div class="mb-3">
                                            <label for="exampleInputEmail9" class="form-label text-light">Nom et Prénom</label>
                                            <input type="text" class="form-control" id="name" name="name" />
                                            <input type="hidden" id="name" name="Town" value={Town} />
                                        </div>
                                        <div class="mb-3">
                                            <label for="exampleInputPassword1" class="form-label text-light">Numéro de Téléphone</label>
                                            <input type="text" class="form-control" id="phone" name="phone" />
                                        </div>
                                        <div className="d-flex justify-content-between mt-4" >
                                            <input className="btn btn-danger btn-md w-100 orange fw-bold " type="submit" value="valider" />
                                        </div>
                                    </form>
                                }

                                {!FormVisibleOne &&

                                    <div className="text-center py-5 my-5 animate__animated animate__fadeIn animate__delay-0.5s " >
                                        <h4 className="text-white" > Formulaire envoyé </h4>
                                        <img src={valid} class="rounded float-left w-25" alt="valid"></img>
                                    </div>
                                }

                            </div>

                        </div>

                        <div className="col-lg-8">

                            <div className="embed-responsive embed-responsive-16by9 h-100 ">
                                <iframe title="video" className="embed-responsive-item h-100 w-100 ytb" src="https://www.youtube.com/embed/EVprdQk3OBI" allowfullscreen></iframe>
                            </div>


                        </div>

                    </div>

                </div>

            </div>

            {/* speed free vhu */}
            <div className="position-relative overflow-hidden p-3 p-md-5 text-center text-light pieces ">


                <div class="col-md-9 mx-auto ">

                    <div className="row pb-5  ">
                        <div className="col-lg-4 col-md-12 about ">
                            <img src={speed} className="Responsive image w-25" alt="speed" ></img>
                            <h1 className="fw-bold my-2 py-3" ><span className="orange">RAPIDE</span></h1>
                            <p>CAR-CASSE.FR intervient partout dans {Town} pour enlever  votre véhicule hors d'usage sous 24 à 48h. Si vous êtes dans l'urgence,  appelez nous maintenant afin de convenir d'un rendez-vous dans les plus bref délais.</p>
                        </div>
                        <div className="col-lg-4 col-md-12 border-end border-start about ">
                            <img src={free} className="Responsive image w-25" alt="speed" ></img>
                            <h1 className="fw-bold my-2 py-3" ><span className="orange">GRATUIT</span></h1>
                            <p>Nous assurons gratuitement l’enlèvement de tous véhicules destinés à la casse, roulants ou non: Épaves de voitures ou camion, pour tout autre type de vehicule, nous vous invitons à nous contacter. </p>
                        </div>
                        <div className="col-lg-4 col-md-12 about ">
                            <img src={vhu} className="Responsive image w-25" alt="speed" ></img>
                            <h1 className="fw-bold my-2 py-3" ><span className="orange">ÉCOLOGIQUE</span></h1>
                            <p>Un certificat de cession pour destruction vous sera remis au moment de l'enlèvement et votre véhicule sera envoyé vers un centre de destruction (VHU) afin de le dépolluer et le détruire administrativement. </p>
                        </div>
                    </div>

                </div>

                <div class="col-md-7 mx-auto ">

                    <h1 className="fw-bold my-2" >POURQUOI <span className="orange">NOUS ?</span></h1>
                    <h3 class="fw-normal">CAR-CASSE.FR enlève Gratuitement vos épaves dans {Town} </h3>
                    <br />
                    <p class="lead fw-normal ">Besoin de faire enlever un de vos véhicule?
                        Chez CAR-CASSE.FR, nous proposons l’enlèvement de votre véhicule Sans frais, dans {Town} .
                        Nos services d’enlèvement sont disponibles 7j/7 et s’adressent à tout type de véhicules.
                        Pour une prise de RDV rapide, nous vous conseillons de nous contacter directement via le formulaire de contact ci-dessous afin que nous puissions avoir toutes les informations nécessaires, ou par téléphone.
                        Pour toute question concernant un enlèvement d’épave, vous pouvez directement nous contacter</p>
                </div>

            </div>

            {/* map and form */}
            <div className="row parallax m-0 ">

                <img src={hook} class=" hook" alt="Responsive"></img>

                <div className="col-lg-6 col-sm-12 text-center left ">



                    <div class="col-12 col-lg-6 pt-5 mx-auto text-dark text-center titles">
                        <h4 class=" fw-light"> <span className="fw-bold text-dark" >ENLEVEMENT</span> DANS</h4>
                        <p class="lead fw-light">{Town.toUpperCase()}</p>
                    </div>

                    <div ref={mapContainer} className="map-container" />



                </div>

                <div className="col-lg-6 col-sm-12 text-center right ">

                    <div class="col-md-8 pt-5 mx-auto text-light text-center">
                        <h4 class=" fw-light"> <span className="fw-bold text-warning" >FORMULAIRE</span> DE CONTACT</h4>
                        <p class="lead fw-light">DÉTAILLÉ</p>
                    </div>

                    {FormVisibleTwo &&

                        <form ref={fullform} onSubmit={SendFullInformations}>

                            <div className="row mx-5">
                                <div className="col-lg-6" >
                                    <div class="form-group">
                                        <input type="text" class="form-control input " name="fullName" id="fullName" aria-describedby="emailHelp" placeholder="Nom & Prénom" />
                                        <input type="hidden" id="name" name="Town" value={Town} />
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div class="form-group">
                                        <input type="text" class="form-control input " name="phone" id="phone" aria-describedby="emailHelp" placeholder="Numéro de téléphone" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mx-5">
                                <div className="col-lg-6" >
                                    <div class="form-group">
                                        <input type="text" class="form-control input " name="mail" id="mail" aria-describedby="emailHelp" placeholder="Adresse Mail" />
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div class="form-group">
                                        <input type="text" class="form-control input " name="takeOffplace" id="takeOffplace" aria-describedby="emailHelp" placeholder="Lieu de l'enlèvement du véhicule" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mx-5">
                                <div className="col-lg-6" >
                                    <div class="form-group">
                                        <input type="text" class="form-control input " name="carId" id="carId" aria-describedby="emailHelp" placeholder="Immatriculation du véhicule" />
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div class="form-group">
                                        <input type="text" class="form-control input " name="carModel" id="carModel" aria-describedby="emailHelp" placeholder="Modèle du Véhicule" />
                                    </div>
                                </div>
                            </div>

                            <div className="row mx-5">
                                <div className="col-lg-6" >
                                    <div class="form-group">
                                        <select class="form-select input text-secondary" name="CGStatus" aria-label="Default select example" >
                                            <option selected> Statut de la carte grise</option>
                                            <option value="CG à jour">CG à jour</option>
                                            <option value="CG barrée">CG barrée</option>
                                            <option value="CG perdu">CG perdu</option>
                                            <option value="Autre cas">Autre cas</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6" >
                                    <div class="form-group">
                                        <div class="form-group">
                                            <select class="form-select input text-secondary " name="VHStatus" aria-label="Default select example" >
                                                <option selected>Etat du véhicule</option>
                                                <option value="Véhicule roulant">Véhicule roulant</option>
                                                <option value="Véhicule en panne">Véhicule en panne</option>
                                                <option value="Véhicule accidenté">Véhicule accidenté</option>
                                                <option value="Véhicule incomplet">Véhicule incomplet</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mx-5">
                                <div className="col-12" >
                                    <div class="form-group">
                                        <textarea class="form-control textarea " name="comment" id="comment" rows="3" placeholder="Commentaire" ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-8 p-5 mx-auto text-light text-center ">

                                <input class="btn btn-warning px-5 py-3 " type="submit" value="valider" />
                            </div>


                        </form>
                    }

                    {!FormVisibleTwo &&

                        <div className="text-center py-5 my-5 animate__animated animate__fadeIn animate__delay-0.5s " >
                            <h4 className="text-white" > Formulaire envoyé </h4>
                            <img src={valid} class="rounded float-left w-25" alt="valid"></img>
                        </div>
                    }

                </div>

            </div>

            {/* foire au question footer */}
            <div className="position-relative overflow-hidden p-3 p-md-5 cards">

                <div class="col-md-5 p-lg-5 mx-auto text-center">
                    <h1 class="display-4 fw-bold">Foire aux questions</h1>
                    <p class="lead fw-normal">Vous trouverez ci-dessous, la réponse détaillée aux fréquentes questions demandées par nos clients</p>
                </div>

                <div class="row  mb-5 py-lg-4 px-lg-5 mx-lg-5">

                    <div className="col-lg-8 ">

                        <div class="p-3 mb-4 bg-success text-light rounded-3">
                            <div class="container-fluid py-5 ">
                                <h2 class="display-6 fw-bold  ">Edition du Cerificat de Situation Administrative</h2>
                                <p class="fs-4 ">Pour toute cession ou destruction de véhicule un Certificat de Situation Administrative Simple ou Détaillé doit être fourni et être édité depuis moins de 15 jours.
                                    Afin de pouvoir édité votre Certificat de Situation Administrative, vous devez être muni de votre Carte Grise et faire une demande en ligne au SIV. à partir du lien suivant:
                                </p>
                                <a href="https://siv.interieur.gouv.fr/map-usg-ui/do/csa_retour_dem_certificat" target="_blank" rel="noreferrer" class="btn btn-outline-light btn-lg" type="button">Cliquez-ici</a>
                                <a href="#Video1" data-bs-toggle="modal" data-bs-target="#Video1" rel="noreferrer" class="btn btn-outline-light btn-lg mx-3" type="button">Tutoriel</a>

                                {/* video */}
                                <div class="modal fade " id="Video1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-xl">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Mentions Légales CAR-CASSE.FR</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body ytb">

                                                <div className="embed-responsive embed-responsive-16by9 h-100">
                                                    <iframe title="video" className="embed-responsive-item h-100 w-100 ytb" src="https://www.youtube.com/embed/gplzbNRpy80" allowfullscreen></iframe>
                                                </div>

                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row align-items-md-stretch">
                            <div class="col-lg-6 pb-4">
                                <div class="h-100 p-5 text-white bg-dark rounded-3">
                                    <h1 class="display-6 fw-bold">Certificat de cession d'un véhicule d'occasion (CERFA 15776*02) </h1>
                                    <p> Le formulaire doit être rempli par l’ancien propriétaire et le nouveau propriétaire (l’épaviste, ou le destructeur), puis être signé par les 2 parties.
                                        S’il y a plusieurs cotitulaires, chacun doit le signer.
                                        Le vendeur conserve l’exemplaire n°1 et remet l’exemplaire n°2 au nouveau propriétaire avec les autres éléments nécessaires à la vente.
                                        Pour éditer un certificat de cession, vous pouvez vous rendre sur le lien suivant :
                                    </p>
                                    <a href="https://www.service-public.fr/simulateur/calcul/15776" target="_blank" rel="noreferrer" class="btn btn-outline-light" type="button">Cliquez-ici</a>
                                    <a href="#Video3" data-bs-toggle="modal" data-bs-target="#Video3" rel="noreferrer" class="btn btn-outline-light btn-md mx-3" type="button">Tutoriel</a>

                                    {/* video */}
                                    <div class="modal fade " id="Video3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-xl">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Mentions Légales CAR-CASSE.FR</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body ytb">

                                                    <div className="embed-responsive embed-responsive-16by9 h-100">
                                                        <iframe title="video" className="embed-responsive-item h-100 w-100 ytb" src="https://www.youtube.com/embed/8qDIIVZWvcI" allowfullscreen></iframe>
                                                    </div>

                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-6 pb-4">
                                <div class="h-100 p-5 text-light bg-warning rounded-3">
                                    <h1 class="display-6 fw-bold text-dark ">Carte de Grise perdue</h1>
                                    <p className="text-dark" >En cas de perte de votre carte grise, il faudra impérativement remplir une déclaration de perte de carte grise à partir du lien suivant:
                                        <a className="text-dark" href="https://www.service-public.fr/simulateur/calcul/13753" >&nbsp;ici</a> Aussi, afin de pouvoir identifier le véhicule, il vous faudra au moins un des éléments suivants:
                                        Copie de la carte grise Une fiche d’identification du véhicule ainsi qu’un Certificat de Situation Administrative que vous pourrez obtenir en vous connectant sur ANTS</p>
                                    <a href="https://www.service-public.fr/simulateur/calcul/13753" target="_blank" rel="noreferrer" class="btn btn-outline-dark" type="button">Cliquez-ici</a>
                                    <a href="#Video2" data-bs-toggle="modal" data-bs-target="#Video2" rel="noreferrer" class="btn btn-outline-dark mx-3" type="button">Tutoriel</a>

                                    {/* video */}
                                    <div class="modal fade " id="Video2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog modal-xl">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Mentions Légales CAR-CASSE.FR</h5>
                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body ytb">

                                                    <div className="embed-responsive embed-responsive-16by9 h-100">
                                                        <iframe title="video" className="embed-responsive-item h-100 w-100 ytb" src="https://www.youtube.com/embed/ZwBHtQ4CUm0" allowfullscreen></iframe>
                                                    </div>

                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fermer</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-4 pb-4 ">

                        <div class="col-md-12 text-white h-100">
                            <div class="h-100 p-5 orange rounded-3">
                                <h1 class="display-6 fw-bold">En cas de décés du titualire du véhicule </h1>
                                <p className="text-justify ">
                                    En cas de décés du titulaire du véhicule, vous devrez remettre au démolisseur les pièces suivantes : <br></br>
                                    -Exemplaire n°2 signé du formulaire cerfa n°15776, au nom du ou des héritiers, indiquant l’adresse du ou d’un des héritiers
                                    -Certificat de situation administrative de moins de 15 jours
                                    -Attestation sur l’honneur certifiant que le véhicule n’a pas circulé sur la voie publique
                                    -Original de la carte grise, complète (y compris le coupon détachable s’il existe), barrée avec la mention « cédé pour destruction le [jour/mois/année] » et signée par le ou les héritiers
                                    Si la carte grise est au nom du défunt :
                                    -Attestation du notaire certifiant que « Monsieur (ou Madame), né(e) le [jour/mois/année] à [commune], est décédé(e) le [jour/mois/année] à [commune] et que dans la succession se trouve un véhicule » (avec indication de la marque et du numéro d’immatriculation et, si possible, le type et le numéro dans la série du type)
                                    -ou acte de notoriété établi par un notaire
                                    -ou certificat de décès + attestation, signée par tous les héritiers, certifiant qu’il n’existe pas de testament, ni d’autres héritiers, qu’il n’y a pas de contrat de mariage, qu’il n’y a ni procès, ni contestation en cours concernant la qualité d’héritier ou la composition de la succession.
                                    Attention : si la carte grise a été perdue, vous devez fournir la déclaration de perte ou de vol. Source:
                                </p>
                                <a href="https://www.service-public.fr/particuliers/vosdroits/F1480" target="_blank" rel="noreferrer" class="btn btn-outline-light text-white" type="button">Cliquez-ici</a>
                            </div>
                        </div>

                    </div>

                </div>


            </div>

            {/* sticky footer */}
            <nav class="navbar fixed-bottom navbar-expand-sm navbar-dark d-flex justify-content-center sticky">


                <ul class="nav col-12 col-md-auto mb-md-0 animate__animated animate__fadeInUp sticky d-flex justify-content-center">
                    <li><a href="tel:+33756908079" class="text-white nav-link link-dark px-3 m-2 btn btn-success btn-sm " ><i class="fas fa-phone-square-alt"></i>+33 7 56 90 80 79</a></li>
                    <li><a href="mailto:contact@CAR-CASSE.FR" class="text-dark nav-link link-dark px-3 m-2 btn btn-warning btn-sm "> <i class="fas fa-envelope-open-text"></i> contact@car-casse.fr</a></li>
                </ul>



            </nav>


        </>

    )

}

export default Home